import { Link } from "react-router-dom";
import MainLayout from "../../components/layouts/MainLayout";

export default function PaymentFailedPage() {
  return (
    <MainLayout>
      <main>
        <div className="col-12 col-lg-4 mx-auto">
          <div className="card-shadow-box prompt-payment-status">
            <img
              src="https://passpod.com/static/assets/img/rvmp/payment-failed.svg"
              alt="Payment Success"
              className="image-status"
            />
            <h3 className="text-center">Gagal Melakukan Pembayaran</h3>
            <span className="sub-heading">Pembayaran anda gagal</span>
            <div className="order-status-btn-wrapper text-center">
              <Link to="/order" className="btn btn-primary">
                Lihat Status pesanan
              </Link>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
