export default function CheckoutPlaceholder() {
  return (
    <div className="card-shadow-box w-100 checkout-loading">
      <h4>Pemesanan</h4>
      <h6>Rincian Pemesanan</h6>
      <div className="order-placeholder"></div>
      <h6>Detail Wisatawan</h6>
      <div className="order-placeholder"></div>
    </div>
  );
}
