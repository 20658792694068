import { Link } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import WifiBox from "../components/wifi/WifiBox";
import { useModemListHooks } from "../hooks/ModemHooks";
import WifiBoxPlacholder from "../components/wifi/WifiBoxPlacehoder";

let modem_filter = { filter: { currency_code: "IDR", status: "active" } };

export default function WifiAllPage() {
  let { modems, loading } = useModemListHooks(modem_filter);
  return (
    <MainLayout>
      <main>
        <div className="container">
          <h2 className="page-title text-center">Sewa Modem</h2>
          <div className="row">
            {loading
              ? [...Array(8)].map((index) => (
                  <div className="col-12 col-lg-3" key={index}>
                    <WifiBoxPlacholder />
                  </div>
                ))
              : null}
            {modems.map((modem) => (
              <div className="col-6 col-lg-3" key={modem.short_id}>
                <Link to={`/wifi/${modem.short_id}`} className="product-link">
                  <WifiBox modem={modem} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
