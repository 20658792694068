import { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  startOfDay,
  addDays,
  differenceInCalendarDays,
  format,
} from "date-fns";

import MainLayout from "../components/layouts/MainLayout";
import { usePresetOrderHooks } from "../hooks/PresetsHooks";
import { useModemHooks } from "../hooks/ModemHooks";
import DetailTravelerForm from "../components/order/DetailTravelerForm";
import {
  isValidEmail,
  normalizePhone,
  phoneNumberTest,
} from "../utils/validation/FormValidation";
import WifiTotalOrderBox from "../components/wifi/WifiTotalOrderBox";
import { createGuestOrder } from "../api/order";
import BoxAddressPasspod from "../components/wifi/BoxAddressPasspod";

let times = [];

for (let index = 5; index < 22; index++) {
  if (index < 10) {
    times.push(`0${index}:00`);
  } else {
    times.push(`${index}:00`);
  }
}

let times2 = [];
for (let index = 6; index < 23; index++) {
  if (index < 10) {
    times2.push(`0${index}:00`);
  } else {
    times2.push(`${index}:00`);
  }
}

const rentalFee = (divisor, days, wifiPrice, qty) => {
  let daysUsed = Math.ceil(days / divisor);
  let finalPrice = wifiPrice * daysUsed * qty;
  return finalPrice;
};

export default function WifiRentPage() {
  const { presets } = usePresetOrderHooks();

  let { id } = useParams();
  let history = useHistory();

  let [filter, setFilter] = useState({
    id: id,
    currency_code: "IDR",
    qty: 1,
    start_date: format(new Date(), "yyyy-MM-dd"),
  });
  const { modem } = useModemHooks({ filter });

  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderError, setOrderError] = useState({
    error: false,
    errorMsg: "",
  });

  const buyingRef = useRef(null);

  const scrollToForm = () => {
    buyingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [form, setForm] = useState({
    qty: 1,
    start_date: startOfDay(new Date()),
    end_date: addDays(startOfDay(new Date()), 2),
    receive_method: "vending-machine",
    return_method: "self-driver",
  });

  const [bankFrom, setBankForm] = useState({
    bank: "",
    holder_name: "",
    holder_account_number: "",
  });

  const [airportReturn, setAirportReturn] = useState({
    name: "",
    date: addDays(startOfDay(new Date()), 2),
    estimate_time_from: "07:00",
    estimate_time_to: "08:00",
  });

  const [user, setUser] = useState({
    salutation: "Mr.",
    country: "ID",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+62",
    phone_number: "",
  });

  const [errorForm, setErrorForm] = useState({
    bank: "",
    holder_name: "",
    holder_account_number: "",

    airport_name: "",
    airport_date: "",
    airport_estimate_time_from: "",
    airport_estimate_time_to: "",

    salutation: "",
    country: "",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
  });

  useEffect(() => {
    // redirect if order success
    if (orderSuccess) {
      history.push("/checkout");
    }
  }, [history, orderSuccess]);

  const changeModemDate = (field, date) => {
    let formModem = { ...form };

    if (field === "start_date") {
      if (addDays(date, 2) >= form.end_date) {
        formModem.start_date = date;
        formModem.end_date = addDays(date, 2);
        setAirportReturn({ ...airportReturn, date: addDays(date, 2) });
      } else {
        formModem.start_date = date;
      }

      setFilter({ ...filter, start_date: format(date, "yyyy-MM-dd") });
    } else {
      formModem[field] = date;
    }

    if (field === "end_date") {
      setAirportReturn({ ...airportReturn, date: date });
    }

    setForm({ ...formModem });
  };

  const changeFormModem = (field, value) => {
    let formModem = { ...form };

    formModem[field] = value;

    if (field === "qty") {
      setFilter({ ...filter, qty: value });
    }

    setForm({ ...formModem });
  };

  const changeAirportReturn = (field, value) => {
    const formAirport = { ...airportReturn };

    if (!(field === "estimate_time_from" || field === "estimate_time_to")) {
      formAirport[field] = value;
    } else {
      if (
        field === "estimate_time_from" &&
        value > formAirport.estimate_time_to
      ) {
        formAirport.estimate_time_to = times2[times2.indexOf(value) + 1];
      }

      if (
        field === "estimate_time_to" &&
        value < formAirport.estimate_time_from
      ) {
        formAirport.estimate_time_from = times[times.indexOf(value) - 1];
      }

      formAirport[field] = value;
    }

    setAirportReturn({ ...formAirport });
  };

  const changeUser = (form, value) => {
    setUser({ ...user, [form]: value });
  };

  const onSubmitOrder = () => {
    let errorCheck = false;

    let errorUserData = {
      modemId: "",
      bank: "",
      holder_name: "",
      holder_account_number: "",

      airport_name: "",
      airport_date: "",
      airport_estimate_time_from: "",
      airport_estimate_time_to: "",

      salutation: "",
      country: "",
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      phone_number: "",
    };

    // form validation
    if (!modem.id) {
      errorCheck = true;
      errorUserData.modemId =
        "Modem ini tidak valid, Silahkan kembali dan pilih modem terlebih dahulu";
    }
    // bank validation
    if (!bankFrom.bank.trim()) {
      errorCheck = true;
      errorUserData.bank = "Wajib diisi";
    }

    if (!bankFrom.holder_account_number.trim()) {
      errorCheck = true;
      errorUserData.holder_account_number = "Wajib diisi";
    }

    if (!bankFrom.holder_name.trim()) {
      errorCheck = true;
      errorUserData.holder_name = "Wajib diisi";
    }
    // airport validation
    if (form.return_method === "airport") {
      if (!airportReturn.name.trim()) {
        errorCheck = true;
        errorUserData.airport_name = "Wajib diisi";
      }
    }
    //  user validation
    if (!user.first_name.trim()) {
      errorCheck = true;
      errorUserData.first_name = "Wajib diisi";
    }

    if (!isValidEmail(user.email.trim())) {
      errorCheck = true;
      errorUserData.email = "Wajib diisi dengan email valid";
    }

    if (!phoneNumberTest(normalizePhone(user.phone_number))) {
      errorCheck = true;
      errorUserData.phone_number = "Wajib diisi dengan nomor valid";
    }

    if (!user.last_name.trim()) {
      errorCheck = true;
      errorUserData.last_name = "Wajib diisi";
    }

    if (!errorCheck) {
      let formOrderData = {
        orderable_id: modem.id,
        orderable_type: "modem",
        order_detail: {
          qty: form.qty,
          start_date: format(form.start_date, "yyyy-MM-dd"),
          end_date: format(form.end_date, "yyyy-MM-dd"),
          bank_account: {
            bank: bankFrom.bank,
            holder_name: bankFrom.holder_name,
            holder_account_number: bankFrom.holder_account_number,
          },
          receive_method: "vending-machine",
          return_method: form.return_method,
        },
        user: {
          salutation: user.salutation,
          first_name: user.first_name.trim(),
          last_name: user.last_name.trim(),
          email: user.email,
          phone_number: user.country_code + normalizePhone(user.phone_number),
        },
      };

      if (form.return_method === "airport") {
        formOrderData.order_detail.return_airport = {
          name: airportReturn.name,
          date: format(airportReturn.date, "yyyy-MM-dd"),
          estimate_time_from: airportReturn.estimate_time_from,
          estimate_time_to: airportReturn.estimate_time_to,
        };
      }

      sendGuesOrder(formOrderData);
      //  submit data
    } else {
      setErrorForm({ ...errorUserData });
      setOrderError({
        error: true,
        errorMsg:
          "Mohon maaf, data Anda masih belum benar. Mohon cek kembali data Anda",
      });
      scrollToForm();
    }
  };

  const sendGuesOrder = async (formData) => {
    setOrderLoading(true);
    try {
      let { data } = await createGuestOrder(formData);
      localStorage.setItem("orderId", data.data?.order_id);

      setOrderSuccess(true);
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";

      if (error.response) {
        message = error.response.data.message;
      }

      setOrderError({ error: true, errorMsg: message });
    }

    scrollToForm();
    setOrderLoading(false);
  };

  return (
    <MainLayout title={modem.title || ""}>
      <main>
        <div className="container">
          <h2 className="page-title">
            <Link to="/wifi-all">Sewa Modem</Link> {">"} {modem.title}
          </h2>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="card-shadow-box" ref={buyingRef}>
                {/* Alert Response From Backend */}
                {orderError.error && (
                  <div className="alert alert-danger">
                    <button
                      type="button"
                      className="close"
                      onClick={() =>
                        setOrderError({ error: false, errorMsg: "" })
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    {orderError.errorMsg}
                  </div>
                )}
                <h6>Informasi Modem</h6>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label>Modem</label>
                      <input
                        type="text"
                        disabled
                        value={modem.title || ""}
                        className={`form-control ${
                          errorForm.modemId && "is-invalid"
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errorForm.modemId}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label>Jumlah Modem</label>
                      <select
                        className="form-control"
                        value={form.qty}
                        onChange={(event) =>
                          changeFormModem("qty", event.target.value)
                        }
                      >
                        {[...Array(8)].map((dt, index) => (
                          <option value={index + 1} key={index}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label>Tanggal Keberangkatan</label>
                      <DatePicker
                        selected={form.start_date}
                        className="form-control"
                        dateFormat={"dd MMMM yyyy"}
                        onChange={(date) => changeModemDate("start_date", date)}
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <label>Tanggal Kepulangan</label>
                      <DatePicker
                        selected={form.end_date}
                        className="form-control"
                        dateFormat={"dd MMMM yyyy"}
                        onChange={(date) => changeModemDate("end_date", date)}
                        minDate={addDays(form.start_date, 2)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-shadow-box d-block d-lg-none">
                <h6>Deskripsi</h6>
                <span className="modem-description-info">
                  {modem.description_ind}
                </span>
              </div>
              <div className="card-shadow-box">
                <div className="content info-bank">
                  <h6>Informasi Bank</h6>
                  <span className="bank-info">
                    Informasi Bank Anda diperlukan untuk REFUND DEPOSIT. Kami
                    akan mengembalikan deposit anda melalui bank transfer dalam
                    3 hari kerja setelah pengembalian modem. Kami sangat
                    menyarankan untuk menggunakan rekening BCA, agar refund
                    depositnya bisa real time di terima setelah modem di
                    kembalikan. Apabila ada kesalahan pengisian nomor rekening
                    untuk refund deposit oleh customer maka akan diproses ulang
                    yang mengakibatkan proses refund lebih lama. Oleh karena itu
                    pengisian nomor rekening ini harus benar, sehingga refund
                    bisa dilakukan tepat waktu.
                  </span>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label>Bank</label>
                        <select
                          className={`form-control ${
                            errorForm.bank && "is-invalid"
                          }`}
                          value={bankFrom.bank}
                          onChange={(event) =>
                            setBankForm({
                              ...bankFrom,
                              bank: event.target.value,
                            })
                          }
                        >
                          <option value="">-- Pilih Bank --</option>
                          {presets.banks?.map((bank, index) => (
                            <option value={bank} key={index}>
                              {bank}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">{errorForm.bank}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="form-group">
                        <label>Nomor Rekening</label>
                        <input
                          type="number"
                          min={0}
                          className={`form-control ${
                            errorForm.holder_account_number && "is-invalid"
                          }`}
                          value={bankFrom.holder_account_number}
                          onChange={(event) =>
                            setBankForm({
                              ...bankFrom,
                              holder_account_number: event.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">
                          {errorForm.holder_account_number}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                      <div className="form-group">
                        <label>Nama Rekening</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errorForm.holder_name && "is-invalid"
                          }`}
                          value={bankFrom.holder_name}
                          onChange={(event) =>
                            setBankForm({
                              ...bankFrom,
                              holder_name: event.target.value,
                            })
                          }
                        />
                        <div className="invalid-feedback">
                          {errorForm.holder_name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content wifi-return-receive">
                  <h6>Pengambilan dan Pengembalian Modem</h6>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <span className="title">Form Pengambilan Modem</span>
                      <div className="vending-machine-form">
                        <span className="sub-title">Vending Machine</span>
                        <span className="info">
                          Vending machine tersedia di Terminal 2F Keberangkatan
                          Internasional Bandara Internasional Soekarno-Hatta
                          (sebelum travelator menuju <i>boarding lounge</i>)
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <span className="title">Form Pengembalian Modem</span>
                      <div className="form-group mb-0">
                        <div className="row">
                          <div className="col-auto">
                            <label>
                              <input
                                type="radio"
                                checked={form.return_method === "self-driver"}
                                value="self-driver"
                                onChange={(event) =>
                                  setForm({
                                    ...form,
                                    return_method: event.target.value,
                                  })
                                }
                                name="return_method"
                              />{" "}
                              Antar Sendiri
                            </label>
                          </div>
                          <div className="col-auto">
                            <label>
                              <input
                                type="radio"
                                checked={form.return_method === "airport"}
                                value="airport"
                                onChange={(event) =>
                                  setForm({
                                    ...form,
                                    return_method: event.target.value,
                                  })
                                }
                                name="return_method"
                              />{" "}
                              Bandara
                            </label>
                          </div>
                        </div>
                      </div>
                      {form.return_method !== "airport" ? (
                        <BoxAddressPasspod />
                      ) : (
                        <div id="airport-form">
                          <div className="form-group pt-0">
                            <label>Terminal Bandara</label>
                            <select
                              className={`form-control ${
                                errorForm.airport_name && "is-invalid"
                              }`}
                              value={airportReturn.name}
                              onChange={(event) =>
                                changeAirportReturn("name", event.target.value)
                              }
                            >
                              <option value="">
                                -- Pilih Lokasi Pengembalian --
                              </option>
                              {presets.airports?.map((airport, index) => (
                                <option value={airport} key={index}>
                                  {airport}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              {errorForm.airport_name}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Tanggal Pengembalian</label>
                            <DatePicker
                              selected={airportReturn.date}
                              className="form-control"
                              dateFormat={"dd MMMM yyyy"}
                              onChange={(date) =>
                                changeAirportReturn("date", date)
                              }
                              minDate={form.end_date}
                              maxDate={addDays(form.end_date, 2)}
                            />
                          </div>
                          <div className="form-group">
                            <label>Perkiraan Waktu Pengembalian</label>
                            <div className="form-row">
                              <div className="col">
                                <select
                                  className="form-control"
                                  value={airportReturn.estimate_time_from}
                                  onChange={(event) =>
                                    changeAirportReturn(
                                      "estimate_time_from",
                                      event.target.value
                                    )
                                  }
                                >
                                  {times.map((time, index) => (
                                    <option key={index} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-auto d-flex align-items-center">
                                sampai
                              </div>
                              <div className="col">
                                <select
                                  className="form-control"
                                  value={airportReturn.estimate_time_to}
                                  onChange={(event) =>
                                    changeAirportReturn(
                                      "estimate_time_to",
                                      event.target.value
                                    )
                                  }
                                >
                                  {times2.map((time, index) => (
                                    <option key={index} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Detail User  */}
                <DetailTravelerForm
                  user={user}
                  errorForm={errorForm}
                  changeUser={(form, value) => changeUser(form, value)}
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <WifiTotalOrderBox
                qty={form.qty}
                price={modem.price || 0}
                days={
                  differenceInCalendarDays(form.end_date, form.start_date) + 1
                }
                rental_fee={rentalFee(
                  modem.divisor || 1,
                  differenceInCalendarDays(form.end_date, form.start_date) + 1,
                  modem.price || 0,
                  form.qty
                )}
                deposit={presets.deposit || 0}
              />
              <div className="card-shadow-box d-none d-lg-block">
                <h6>Deskripsi</h6>
                <span className="modem-description-info">
                  {modem.description_ind}
                </span>
              </div>
            </div>
          </div>
          <div className="row justify-content-right">
            <div className="col-12 col-lg-8">
              {orderLoading && (
                <div className="alert alert-warning">
                  <b>Mohon menunggu</b> Sedang mengolah pesananan anda.
                </div>
              )}
              <button
                className="btn btn-primary btn-block"
                disabled={orderLoading}
                onClick={() => onSubmitOrder()}
              >
                {orderLoading ? "Menyiapkan pesanan anda" : "Pesan Sekarang"}
              </button>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
