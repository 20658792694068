import { useState, useEffect } from "react";
import { getProductById, getProductList } from "../api/wifi";

export function useModemListHooks({ filter }) {
  const [modems, setModems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getModems = async (filter = {}) => {
    setLoading(true);
    try {
      const { data } = await getProductList({ ...filter });
      setModems(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getModems(filter);
  }, [filter]);

  return {
    modems,
    errorMsg,
    loading,
    getModems,
  };
}

export function useModemHooks({ filter }) {
  const [modem, setModem] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getModems = async (filter, id) => {
    setLoading(true);
    setFetched(false);
    try {
      const { data } = await getProductById(id, filter);
      setModem(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getModems(filter, filter.id);
  }, [filter]);

  return {
    modem,
    fetched,
    errorMsg,
    loading,
    getModems,
  };
}
