import { apiBasic } from "./base";

export function getShopProductList(params) {
  let api = apiBasic();
  return api.get("inventories", { params });
}

export function getShopProductById(id, params) {
  let api = apiBasic();
  return api.get(`inventories/${id}/items`, { params });
}
