import { useMemo } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import MainLayout from "../../components/layouts/MainLayout";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PaymentSuccessPage() {
  let query = useQuery();

  console.log(query.get("transaction_status"));
  if (query.get("transaction_status") === "pending") {
    return <Redirect to="/payment/pending" />;
  }

  return (
    <MainLayout>
      <main>
        <div className="col-12 col-lg-4 mx-auto">
          <div className="card-shadow-box prompt-payment-status">
            <img
              src="https://passpod.com/static/assets/img/rvmp/payment-success.svg"
              alt="Payment Success"
              className="image-status"
            />
            <h3 className="text-center">Pembayaran Sukses</h3>
            <span className="sub-heading">Pembayaran anda sudah berhasil</span>
            <div className="order-status-btn-wrapper text-center">
              <Link to="/order" className="btn btn-primary">
                Lihat Status pesanan
              </Link>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
