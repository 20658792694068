import { Link } from "react-router-dom";
import { useState } from "react";
import MainLayout from "../components/layouts/MainLayout";
import ShopBox from "../components/shop/ShopBox";
import { useShopListHooks } from "../hooks/ShopHooks";
import WifiBoxPlacholder from "../components/wifi/WifiBoxPlacehoder";
import Pagination from "../components/ui/Pagination";

export default function ShopAllPage() {
  const [filter, setFilter] = useState({
    limit: 12,
    currency_code: "IDR",
    category: "travel-smart-card",
  });

  let { shopProducts, meta, loading } = useShopListHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout>
      <main>
        <div className="container">
          <h2 className="page-title text-center">Travel SIM Card</h2>
          <div className="row">
            {loading
              ? [...Array(8)].map((dt, index) => (
                  <div className="col-6 col-lg-3" key={index}>
                    <WifiBoxPlacholder />
                  </div>
                ))
              : null}

            {shopProducts.map((shop) => (
              <div className="col-6 col-lg-3" key={shop.id}>
                <Link to={`/shop/${shop.id}`} className="product-link">
                  <ShopBox shop={shop} />
                </Link>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            {meta.count > meta.limit && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.limit)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
