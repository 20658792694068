import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar-travy">
      <Link to="/">
        <img
          src="https://cdn.passpod.com/image/section/0cf65180-10ad-11ee-8ff5-3f21df895789.png"
          alt="Logo travelinPod"
        />
      </Link>
    </nav>
  );
}

export default Navbar;
