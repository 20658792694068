import { useState, createContext } from "react";

export const OrderQueryContext = createContext(null);

export const OrderQueryProvider = ({ children }) => {
  const [order, setOrder] = useState({});
  const [orderResponse, setOrderResponse] = useState({
    payment_method: "",
    response: null,
  });

  return (
    <OrderQueryContext.Provider
      value={{ order, setOrder, orderResponse, setOrderResponse }}
    >
      {children}
    </OrderQueryContext.Provider>
  );
};
