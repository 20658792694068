import { useState, useEffect } from "react";
import { getGuestOrder } from "../api/order";

export function useGuestOrderHooks() {
  const [orderData, setOrderData] = useState({});
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getOrder = async (id) => {
    setLoading(true);
    setFetched(false);
    try {
      const { data } = await getGuestOrder(id);
      setOrderData(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    let id = localStorage.getItem("orderId");
    if (id) {
      setOrderId(id);
      getOrder(id);
    } else {
      setErrorMsg("Order Empty");
    }
  }, []);

  return {
    orderData,
    orderId,
    loading,
    fetched,
    errorMsg,
  };
}
