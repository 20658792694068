import { useBannersHooks } from "../hooks/BannerHooks";
import Slider from "react-slick";

let banner_filter = { filter: { type: "travypass" } };

const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function BannerHome() {
  const { banners, loading, fetched } = useBannersHooks(banner_filter);
  return (
    <section className="banner">
      {loading && <div className="loading" />}
      {fetched && (
        <Slider {...settings}>
          {banners.map((banner) => (
            <img
              key={banner.id}
              src={banner.image_url.web}
              className="img-fluid"
              alt="Banner"
            />
          ))}
        </Slider>
      )}
    </section>
  );
}
