export default function DetailTravelerForm({ user, errorForm, changeUser }) {
  return (
    <div className="content detail-traveler">
      <h6>Detail Wisatawan</h6>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Negara</label>
            <select
              className={`form-control ${errorForm.country && "is-invalid"}`}
              value={user.country}
              onChange={(event) => changeUser("country", event.target.value)}
            >
              <option value="ID">Indonesia</option>
            </select>
            <div className="invalid-feedback">{errorForm.country}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Title</label>
            <select
              className={`form-control ${errorForm.salutation && "is-invalid"}`}
              value={user.salutation}
              onChange={(event) => changeUser("salutation", event.target.value)}
            >
              <option value="Mr.">Mr</option>
              <option value="Mrs.">Mrs</option>
              <option value="Ms.">Ms</option>
            </select>
            <div className="invalid-feedback">{errorForm.salutation}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Nama Depan</label>
            <input
              type="name"
              className={`form-control ${errorForm.first_name && "is-invalid"}`}
              value={user.first_name}
              onChange={(event) => changeUser("first_name", event.target.value)}
            />
            <div className="invalid-feedback">{errorForm.first_name}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Nama Belakang</label>
            <input
              type="name"
              className={`form-control ${errorForm.last_name && "is-invalid"}`}
              value={user.last_name}
              onChange={(event) => changeUser("last_name", event.target.value)}
            />
            <div className="invalid-feedback">{errorForm.last_name}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Kode Negara</label>
            <select
              className={`form-control ${
                errorForm.country_code && "is-invalid"
              }`}
              value={user.country_code}
              onChange={(event) =>
                changeUser("country_code", event.target.value)
              }
            >
              <option value="+62">+62 (Indonesia)</option>
            </select>
            <div className="invalid-feedback">{errorForm.country_code}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Nomor Telepon</label>
            <input
              type="number"
              className={`form-control ${
                errorForm.phone_number && "is-invalid"
              }`}
              value={user.phone_number}
              onChange={(event) =>
                changeUser("phone_number", event.target.value)
              }
            />
            <div className="invalid-feedback">{errorForm.phone_number}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className={`form-control ${errorForm.email && "is-invalid"}`}
              value={user.email}
              onChange={(event) => changeUser("email", event.target.value)}
            />
            <div className="invalid-feedback">{errorForm.email}</div>
            <small>
              Pastikan email Anda valid. Data pemesanan akan dikirimkan ke
              alamat email tersebut.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
