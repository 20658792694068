import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";

class NotFoundPage extends Component {
  render() {
    return (
      <MainLayout>
        <div className="" style={{ minHeight: "100vh", padding: "100px 0" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="card card-shadow-box o-hidden border-0 shadow-lg">
                  <div className="card-body p-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="text-center mb-5">
                          <h1 className="h1 mb-2">404</h1>
                          <h3 className="h5 mb-4">Not Found</h3>
                          <Link to="/" className="btn btn-primary">
                            Kembali ke Halaman Utama
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default NotFoundPage;
