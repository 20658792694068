export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img
              src="https://cdn.passpod.com/image/section/398ecab0-10ad-11ee-8ff5-3f21df895789.png"
              alt="Logo travelinPod"
              className="logo-footer"
            />
          </div>
          <div className="col-12">
            <span className="footer-copyright">
              © 2023, travelinPod. All Right Reserved
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
