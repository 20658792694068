import { useState, useEffect } from "react";
import { getShopProductById, getShopProductList } from "../api/shop";

export function useShopListHooks({ filter }) {
  const [shopProducts, setShopProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({ count: 0, limit: 12, page: 1 });
  const [errorMsg, setErrorMsg] = useState(false);

  const getShopList = async (filter = {}) => {
    setLoading(true);
    setShopProducts([]);
    try {
      const { data } = await getShopProductList({ ...filter });
      setShopProducts(data.data);
      setMeta(data.meta);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getShopList(filter);
  }, [filter]);

  return {
    shopProducts,
    meta,
    errorMsg,
    loading,
    getShopList,
  };
}

export function useShopHooks({ id }) {
  const [shopProduct, setShopProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getShop = async (id) => {
    setLoading(true);
    setFetched(false);
    setShopProduct({});
    try {
      const { data } = await getShopProductById(id);
      setShopProduct(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getShop(id);
  }, [id]);

  return {
    shopProduct,
    errorMsg,
    fetched,
    loading,
    getShop,
  };
}
