import BannerHome from "../components/BannerHome";
import MainLayout from "../components/layouts/MainLayout";
import ShopList from "../components/shop/ShopList";
import WifiList from "../components/wifi/WifiList";

export default function HomePage() {
  return (
    <MainLayout>
      <main>
        <div className="container">
          <BannerHome />
          <WifiList />
          <ShopList />
        </div>
      </main>
    </MainLayout>
  );
}
