import { currencyIDR } from "../../utils/helper/numerictools";

export default function WifiBox({ modem }) {
  return (
    <div className="product-box">
      <div className="card-img">
        <img src={modem.image_url} alt={modem.name} />
      </div>
      <div className="card-body">
        <span className="title">{modem.title}</span>
        <div className="price">
          <span className="normal_price">
            {modem.normal_price > modem.price
              ? currencyIDR(modem.normal_price)
              : null}
          </span>
          <span className="price">{currencyIDR(modem.price)}</span>
        </div>
      </div>
    </div>
  );
}
