import axios from "axios";
const base_api = process.env.REACT_APP_API_URL;
const passpod_base_url = process.env.REACT_APP_PASSPOD_BASE_URL;

export const apiBasic = () =>
  axios.create({
    baseURL: base_api,
    timeout: 600000,
  });

export function assetsPasspodHelper(path_file) {
  return passpod_base_url + path_file;
}
