import { Link } from "react-router-dom";
import WifiBox from "./WifiBox";
import { useModemListHooks } from "../../hooks/ModemHooks";
import WifiBoxPlacholder from "./WifiBoxPlacehoder";

let modem_filter = {
  filter: { limit: 8, currency_code: "IDR", status: "active" },
};

export default function WifiList() {
  let { modems, loading } = useModemListHooks(modem_filter);

  return (
    <section className="product-list wifi-list">
      <div className="title-head">
        <h4>Sewa Modem</h4>
        <Link to="/wifi-all" className="btn btn-primary">
          Lihat Semua
        </Link>
      </div>
      <div className="row">
        {loading
          ? [...Array(4)].map((dt, index) => (
              <div className="col-12 col-lg-3" key={index}>
                <WifiBoxPlacholder />
              </div>
            ))
          : null}
        {modems.map((modem) => (
          <div className="col-6 col-lg-3" key={modem.short_id}>
            <Link to={`/wifi/${modem.short_id}`} className="product-link">
              <WifiBox modem={modem} />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
