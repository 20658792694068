import { apiBasic } from "./base";

export function getAllBanners(params) {
  let api = apiBasic();
  return api.get("banners", { params });
}

export function getPresetOrder(params) {
  let api = apiBasic();
  return api.get("presets/orders", { params });
}
