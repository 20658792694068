import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import {
  ModemOrder,
  ShopOrder,
  TravelerInformation,
} from "../components/order/CheckoutOrder";
import CheckoutPlaceholder from "../components/order/CheckoutPlaceholder";
import OrderNotReady from "../components/order/OrderNotReady";
import PaymentBCA from "../components/payment/PaymentBCA";
import { useGuestOrderHooks } from "../hooks/OrderHooks";
import { currencyIDR } from "../utils/helper/numerictools";
import { OrderQueryContext } from "../utils/context/OrderQueryContext";
import PaymentQris from "../components/payment/PaymentQris";
import PaymentWithLink from "../components/payment/PaymentWithLink";
import { assetsPasspodHelper } from "../api/base";

export default function CheckoutPaymentPage() {
  const { orderData, orderId, loading, fetched } = useGuestOrderHooks();
  const { orderResponse } = useContext(OrderQueryContext);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue =
        "Kode pembayaran akan hilang jika anda melakukan proses ini. Apakah anda yakin?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <MainLayout>
      <main>
        <div className="container">
          <h3 className="text-center mb-4">Pembayaran</h3>
          <div className="row">
            {/* Order Box */}
            {loading && (
              <div className="col-12 col-lg-8 mx-auto">
                <CheckoutPlaceholder />
              </div>
            )}
            {orderId === null && (
              <div className="col-12 col-lg-8 mx-auto">
                <OrderNotReady />
              </div>
            )}

            {fetched && (
              <div className="col-12 col-lg-8 mx-auto">
                <div className="card-shadow-box">
                  <div className="order-payment-title">
                    <h4>Pemesanan</h4>
                    <span className="order-number">
                      {orderData.order_number}
                    </span>
                  </div>
                  <hr className="order-divider" />
                  {orderData.order_payment?.payment_method ===
                    "bank-transfer" && (
                    <PaymentBCA final_amount={orderData?.final_amount} />
                  )}

                  {/*  Payment QRIS */}
                  {orderData.order_payment?.payment_method === "qris" &&
                    orderResponse.payment_method === "" && (
                      <div className="alert alert-warning">
                        <span className="d-block">
                          Kode pembayaran QRIS sudah tidak tersedia.{" "}
                          <Link to="/order">Cek Status Pembayaran</Link>
                        </span>
                      </div>
                    )}
                  {orderResponse.payment_method === "qris" && (
                    <PaymentQris
                      barcode_image={orderResponse.response.qr_url}
                      expired_at={orderResponse.response.expired_at}
                      final_amount={orderData.final_amount}
                    />
                  )}

                  {orderData.order_payment?.payment_method === "gopay" &&
                    orderResponse.payment_method === "" && (
                      <div className="alert alert-warning">
                        <span className="d-block">
                          Kode pembayaran sudah tidak tersedia.{" "}
                          <Link to="/order">Cek Status Pembayaran</Link>
                        </span>
                      </div>
                    )}

                  {orderResponse.payment_method === "gopay" && (
                    <PaymentWithLink
                      img={`${assetsPasspodHelper(
                        "static/assets/img/rvmp/payment/gopay-big.png"
                      )}`}
                      payment_name="Gopay"
                      amount={currencyIDR(orderData.final_amount || 0)}
                      expired_at={orderResponse.response.expired_at}
                      link={orderResponse.response.checkout_url}
                    />
                  )}

                  {/* <PaymentVA />
                   */}
                  <hr className="order-divider" />
                  <h6>Rincian Pemesanan</h6>
                  {orderData.order_details?.map((order) => (
                    <div key={order.id}>
                      {order.type === "inventory" && (
                        <ShopOrder order={order} />
                      )}
                      {order.type === "modem" && <ModemOrder order={order} />}
                    </div>
                  ))}
                  <div className="order-list text-right">
                    <span className="order-label title">
                      Biaya Administrasi
                    </span>
                    <span className="order-label value">
                      {currencyIDR(orderData?.total_admin_fee)}
                    </span>
                  </div>
                  <div className="order-list text-right">
                    <span className="order-label title">Total Akhir</span>
                    <span className="order-label value">
                      {currencyIDR(orderData?.final_amount)}
                    </span>
                  </div>
                  <h6>Detail Wisatawan</h6>
                  <TravelerInformation
                    salutation={orderData.salutation}
                    first_name={orderData.first_name}
                    last_name={orderData.last_name}
                    phone_number={orderData.phone_number}
                    email={orderData.email}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
