import Navbar from "./Navbar";
import Footer from "./Footer";
import { useEffect } from "react";

export default function MainLayout({ title, children }) {
  useEffect(() => {
    if (title) {
      document.title = title + " | travelinPod";
    } else {
      document.title = "travelinPod";
    }
  }, [title]);

  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}
