export default function BoxAddressPasspod() {
  return (
    <div className="vending-machine-form">
      <span className="sub-title">Kantor Passpod</span>
      <span className="info">
        Jl. Alaydrus No. 66 B/C, Petojo Utara, Gambir, Jakarta Pusat 10130
      </span>
    </div>
  );
}
