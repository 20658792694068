import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { assetsPasspodHelper } from "../api/base";
import {
  paymentBankGuestOrder,
  paymentGopayGuestOrder,
  paymentQrisGuestOrder,
} from "../api/order";
import MainLayout from "../components/layouts/MainLayout";
import {
  ModemOrder,
  ShopOrder,
  TravelerInformation,
} from "../components/order/CheckoutOrder";
import { useGuestOrderHooks } from "../hooks/OrderHooks";
import CheckoutPlaceholder from "../components/order/CheckoutPlaceholder";
import OrderNotReady from "../components/order/OrderNotReady";
import { OrderQueryContext } from "../utils/context/OrderQueryContext";
import QrIcon from "../assets/img/payment/qr-code.svg";

const PAYMENT_METHOD_AVAILABLE = [
  // {
  //   method: "credit_card",
  //   name: "Kartu Kredit/Debit",
  //   icon: "https://passpod.com/static/assets/img/rvmp/icons/credit-card-payment.svg",
  //   status: "disable",
  // },
  // {
  //   method: "bank-transfer",
  //   name: "Transfer Bank",
  //   icon: assetsPasspodHelper("static/assets/img/rvmp/icons/transfer-bank.svg"),
  //   status: "available",
  // },
  // {
  //   method: "virtual_account",
  //   name: "Virtual Account",
  //   icon: "https://demo.passpod.com/static/assets/img/rvmp/icons/virtual-account-payment.svg",
  //   status: "disable",
  // },
  {
    method: "qris",
    name: "QRIS",
    icon: QrIcon,
    status: "available  ",
  },
  {
    method: "gopay",
    name: "GOPAY",
    icon: assetsPasspodHelper("static/assets/img/rvmp/icons/gopay.png"),
    status: "available  ",
  },
  // {
  //   method: "dana",
  //   name: "DANA",
  //   icon: "https://demo.passpod.com/static/assets/img/rvmp/icons/dana-payment.png",
  //   status: "disable",
  // },
  // {
  //   method: "ovo",
  //   name: "OVO",
  //   icon: "https://demo.passpod.com/static/assets/img/rvmp/icons/ovo-payment.png",
  //   status: "disable",
  // },
  // {
  //   method: "kredivo",
  //   name: "Kredivo",
  //   icon: "https://demo.passpod.com/static/assets/img/rvmp/icons/kredivo-payment.png",
  //   status: "disable",
  // },
  // {
  //   method: "shopeepay",
  //   name: "Shopee Pay",
  //   icon: "https://demo.passpod.com/static/assets/img/rvmp/icons/shopeepay.svg",
  //   status: "disable",
  // },
];

export default function CheckoutPage() {
  const history = useHistory();
  const { setOrderResponse } = useContext(OrderQueryContext);
  const { orderData, orderId, loading, fetched } = useGuestOrderHooks();

  const [paymentMethod, setPaymentMethod] = useState("qris");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState({
    error: false,
    errorMsg: "",
  });

  useEffect(() => {
    if (paymentSuccess) {
      if (paymentMethod === "bank-transfer") {
        history.push("/checkout-payment");
      }

      if (paymentMethod === "qris") {
        history.push("/checkout-payment");
      }

      if (paymentMethod === "gopay") {
        history.push("/checkout-payment");
      }
    }
  }, [history, paymentMethod, paymentSuccess]);

  const paymentBank = async () => {
    setPaymentLoading(true);
    try {
      await paymentBankGuestOrder(orderId);
      setOrderResponse({
        payment_method: "",
        response: null,
      });
      setPaymentSuccess(true);
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";
      if (error.response) {
        message = error.response.data.message;
      }
      setPaymentError({ error: true, errorMsg: message });
    }
    setPaymentLoading(false);
  };

  const paymentQris = async () => {
    setPaymentLoading(true);
    try {
      const { data } = await paymentQrisGuestOrder(orderId);
      setOrderResponse({
        payment_method: "qris",
        response: { ...data.data },
      });
      setPaymentSuccess(true);
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";
      if (error.response) {
        message = error.response.data.message;
      }
      setPaymentError({ error: true, errorMsg: message });
    }
    setPaymentLoading(false);
  };

  const paymentGopay = async () => {
    setPaymentLoading(true);
    try {
      const { data } = await paymentGopayGuestOrder(orderId);
      setOrderResponse({
        payment_method: "gopay",
        response: { ...data.data.payment },
      });
      setPaymentSuccess(true);
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";
      if (error.response) {
        message = error.response.data.message;
      }
      setPaymentError({ error: true, errorMsg: message });
    }
    setPaymentLoading(false);
  };

  const onCLickPay = () => {
    if (paymentMethod === "bank-transfer") {
      paymentBank();
    }

    if (paymentMethod === "qris") {
      paymentQris();
    }

    if (paymentMethod === "gopay") {
      paymentGopay();
    }
  };

  return (
    <MainLayout>
      <main>
        <div className="container">
          <h3 className="text-center mb-4">Checkout</h3>
          <div className="row">
            {loading && <CheckoutPlaceholder />}
            {orderId === null && (
              <div className="col-12 col-lg-8 mx-auto">
                <OrderNotReady />
              </div>
            )}
            {fetched && (
              <>
                {/* Order Box */}
                <div className="col-12">
                  <div className="card-shadow-box">
                    <h4>Pemesanan</h4>
                    <h6>Rincian Pemesanan</h6>
                    {orderData.order_details?.map((order) => (
                      <div key={order.id}>
                        {order.type === "inventory" && (
                          <ShopOrder order={order} />
                        )}
                        {order.type === "modem" && <ModemOrder order={order} />}
                      </div>
                    ))}

                    <h6>Detail Wisatawan</h6>
                    <TravelerInformation
                      salutation={orderData.salutation}
                      first_name={orderData.first_name}
                      last_name={orderData.last_name}
                      phone_number={orderData.phone_number}
                      email={orderData.email}
                    />
                  </div>
                </div>

                {/* Payment */}
                {orderData.status !== "confirmed" && (
                  <div className="col-12">
                    <div className="card-shadow-box">
                      <h5 className="mb-4">Metode Pembayaran</h5>
                      {/* Alert Response From Backend */}
                      {paymentError.error && (
                        <div className="alert alert-danger">
                          <button
                            type="button"
                            className="close"
                            onClick={() =>
                              paymentError({ error: false, errorMsg: "" })
                            }
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          {paymentError.errorMsg}
                        </div>
                      )}
                      <div className="payment-method-list">
                        {PAYMENT_METHOD_AVAILABLE.map((pay_method, index) => (
                          <button
                            className={`payment-method-button ${
                              paymentMethod === pay_method.method && "active"
                            }`}
                            onClick={() => setPaymentMethod(pay_method.method)}
                            key={index}
                          >
                            <img src={pay_method.icon} alt={pay_method.name} />
                            <span className="method-name">
                              {pay_method.name}
                            </span>
                          </button>
                        ))}
                      </div>
                      <button
                        className="btn btn-primary btn-checkout-payment"
                        onClick={() => onCLickPay()}
                        disabled={paymentLoading}
                      >
                        {paymentLoading
                          ? "Memproses Pembayaran"
                          : "Bayar Sekarang"}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
