import { Link } from "react-router-dom";
import { assetsPasspodHelper } from "../../api/base";

export default function ProductNotLoaded() {
  return (
    <div className="card-shadow-box text-center">
      <img
        src={assetsPasspodHelper("static/assets/img/package_empty.png")}
        alt="Tidak ada order"
        className="mb-4"
      />
      <h4>Produk Tidak Ditemukan</h4>
      <p>Silahkan kembali ke halaman utama</p>
      <Link to="/" className="btn btn-primary">
        Kembali ke Halaman Utama
      </Link>
    </div>
  );
}
