import { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import { useShopHooks } from "../hooks/ShopHooks";
import { currencyIDR } from "../utils/helper/numerictools";
import DetailTravelerForm from "../components/order/DetailTravelerForm";
import { ShopDetailLoading } from "../components/shop/ShopPlaceholderLoading";
import { createGuestOrder } from "../api/order";
import {
  isValidEmail,
  normalizePhone,
  phoneNumberTest,
} from "../utils/validation/FormValidation";
import ProductNotLoaded from "../components/ui/ProductNotLoaded";

export default function SimShoPage() {
  const { id } = useParams();
  let history = useHistory();
  const { shopProduct, loading, errorMsg, fetched } = useShopHooks({ id });

  const [form, setForm] = useState({
    selectedColor: 0,
    selectedSize: -1,
    qty: 0,
  });

  const [user, setUser] = useState({
    salutation: "Mr.",
    country: "ID",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+62",
    phone_number: "",
  });

  const [errorForm, setErrorForm] = useState({
    selectedColor: "",
    selectedSize: "",
    qty: "",
    salutation: "",
    country: "",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
  });

  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderError, setOrderError] = useState({
    error: false,
    errorMsg: "",
  });

  const buyingRef = useRef(null);

  const scrollToForm = () => {
    buyingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // redirect if order success
    if (orderSuccess) {
      history.push("/checkout");
    }
  }, [history, orderSuccess]);

  const changeVarian = (index) => {
    setForm({ ...form, selectedColor: index, qty: 0, selectedSize: -1 });
  };

  const changeSubVarian = (index) => {
    setForm({ ...form, qty: 0, selectedSize: index });
  };

  const submitData = () => {
    let errorCheck = false;
    let errorData = {
      selectedColor: "",
      selectedSize: "",
      qty: "",
      salutation: "",
      country: "",
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      phone_number: "",
    };

    // form validation
    if (form.selectedColor < 0) {
      errorCheck = true;
      errorData.selectedColor = "Wajib pilih varian";
    }

    if (form.selectedSize < 0) {
      errorCheck = true;
      errorData.selectedSize = "Wajib pilih sub varian";
    }

    if (form.qty < 1) {
      errorCheck = true;
      errorData.qty = "Wajib beli minimal 1";
    }
    //  user validation
    if (!user.first_name.trim()) {
      errorCheck = true;
      errorData.first_name = "Wajib diisi";
    }

    if (!user.last_name.trim()) {
      errorCheck = true;
      errorData.last_name = "Wajib diisi";
    }

    if (!phoneNumberTest(normalizePhone(user.phone_number))) {
      errorCheck = true;
      errorData.phone_number = "Wajib diisi dengan nomor valid";
    }

    if (!isValidEmail(user.email.trim())) {
      errorCheck = true;
      errorData.email = "Wajib diisi dengan email valid";
    }

    // send data if not error
    if (!errorCheck) {
      let formData = {
        orderable_id:
          shopProduct.varians_repack[form.selectedColor].sizes[
            form.selectedSize
          ].item_inventory_id,
        orderable_type: "inventory",
        currency_code: "IDR",
        order_detail: {
          is_instant: true,
          qty: form.qty,
          receive_method: "vending-machine",
        },
        user: {
          salutation: user.salutation,
          first_name: user.first_name.trim(),
          last_name: user.last_name.trim(),
          email: user.email,
          phone_number: user.country_code + normalizePhone(user.phone_number),
        },
      };
      sendGuesOrder(formData);
    } else {
      setErrorForm({ ...errorData });
      scrollToForm();
    }
  };

  const sendGuesOrder = async (formData) => {
    setOrderLoading(true);
    try {
      let { data } = await createGuestOrder(formData);
      localStorage.setItem("orderId", data.data?.order_id);

      setOrderSuccess(true);
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi";

      if (error.response) {
        message = error.response.data.message;
      }

      setOrderError({ error: true, errorMsg: message });
    }

    scrollToForm();
    setOrderLoading(false);
  };

  const changeQty = (quantity) => {
    if (form.selectedColor >= 0 && form.selectedSize >= 0) {
      let stock =
        shopProduct.varians_repack[form.selectedColor].sizes[form.selectedSize]
          .stock;
      let availability =
        shopProduct.varians_repack[form.selectedColor].sizes[form.selectedSize]
          .availability;
      if (quantity >= 0 && availability !== 0 && quantity <= stock) {
        setForm({ ...form, qty: quantity });
      }
    }
  };

  const changeUser = (form, value) => {
    setUser({ ...user, [form]: value });
  };

  return (
    <MainLayout title={shopProduct.name || ""}>
      <main>
        <div className="container">
          <h2 className="page-title">
            <Link to="/shop-all">SIM Card</Link>
            {" >"} {shopProduct.name}
          </h2>
          <div className="row">
            {errorMsg && (
              <div className="col-12">
                <ProductNotLoaded />
              </div>
            )}
            {loading && (
              <div className="col-12">
                <ShopDetailLoading />
              </div>
            )}
            {fetched && (
              <div className="col-12">
                <div
                  className="card-shadow-box shop-detail-box"
                  ref={buyingRef}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                      <img
                        src={shopProduct.inventory_images[0]?.image_url}
                        alt="SIM Card"
                        className="img-fluid w-100"
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-8">
                      <div className="shop-input">
                        <h2>{shopProduct.name}</h2>
                        <div className="price-section">
                          <div className="price-secondary">
                            {form.selectedSize >= 0 &&
                              form.selectedColor >= 0 && (
                                <>
                                  {shopProduct.normal_price >
                                    shopProduct.varians_repack[
                                      form.selectedColor
                                    ].sizes[form.selectedSize].price &&
                                    currencyIDR(shopProduct.normal_price)}
                                </>
                              )}
                          </div>
                          <div className="price-big">
                            {form.selectedSize >= 0 && form.selectedColor >= 0
                              ? currencyIDR(
                                  shopProduct.varians_repack[form.selectedColor]
                                    .sizes[form.selectedSize].price
                                )
                              : currencyIDR(shopProduct.final_price)}
                          </div>
                        </div>
                        {/* Alert Response From Backend */}
                        {orderError.error && (
                          <div className="alert alert-danger">
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                setOrderError({ error: false, errorMsg: "" })
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            {orderError.errorMsg}
                          </div>
                        )}
                        {/* Change Varian */}
                        <div className="select-variant-list">
                          <span className="variant-label">Varian</span>
                          <div className="variant-list">
                            {shopProduct.varians_repack?.map(
                              (varian, index) => (
                                <button
                                  className={`variant-item ${
                                    index === form.selectedColor ? "active" : ""
                                  }`}
                                  key={index}
                                  onClick={() => changeVarian(index)}
                                >
                                  {varian.varian_colour_name}
                                </button>
                              )
                            )}
                          </div>
                        </div>

                        {/* Change Sub Varian */}
                        <div className="select-variant-list">
                          <span className="variant-label">Sub Varian</span>
                          <div className="variant-list mb-2">
                            {shopProduct.varians_repack[
                              form.selectedColor
                            ]?.sizes?.map((subvarian, index) => (
                              <button
                                className={`variant-item ${
                                  index === form.selectedSize ? "active" : ""
                                }`}
                                key={index}
                                disabled={
                                  subvarian.stock === 0 ||
                                  subvarian.availability === 0
                                }
                                onClick={() => changeSubVarian(index)}
                              >
                                {subvarian.varian_size_name}
                              </button>
                            ))}
                          </div>
                          {errorForm.selectedSize && (
                            <span className="text-danger">
                              {errorForm.selectedSize}
                            </span>
                          )}
                        </div>
                        <div className="quantity-input">
                          <span className="qty-label">Jumlah</span>
                          <div className="qty-form-input mb-2">
                            <button onClick={() => changeQty(form.qty - 1)}>
                              -
                            </button>
                            <input type="text" value={form.qty} disabled />
                            <button onClick={() => changeQty(form.qty + 1)}>
                              +
                            </button>
                          </div>
                          {errorForm.qty && (
                            <span className="text-danger">{errorForm.qty}</span>
                          )}
                        </div>
                        <DetailTravelerForm
                          user={user}
                          errorForm={errorForm}
                          changeUser={(form, value) => changeUser(form, value)}
                        />
                        {orderLoading && (
                          <div className="alert alert-warning">
                            <b>Mohon menunggu</b> Sedang mengolah pesananan
                            anda.
                          </div>
                        )}
                        <button
                          className="btn btn-primary btn-block"
                          disabled={orderLoading}
                          onClick={() => submitData()}
                        >
                          {orderLoading
                            ? "Menyiapkan pesanan anda ..."
                            : "Pesan Sekarang"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-shadow-box">
                  <h5 className="mb-4">Deskripsi</h5>
                  <div className="shop-description-text">
                    {shopProduct.description}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
