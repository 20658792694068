import { parseISO, format } from "date-fns";
import WifiOrder from "../../assets/img/wifi-order.svg";
import SimOrder from "../../assets/img/sim-order.svg";
import PersonOrder from "../../assets/img/person-traveler.svg";
import { currencyIDR } from "../../utils/helper/numerictools";

export function ModemOrder({ order }) {
  return (
    <div className="order-list">
      <div className="row">
        <div className="col-auto">
          <img src={WifiOrder} alt="Modem Order" />
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">Sewa Modem</span>
              <span className="order-label product-title">
                {order.orderable.product.title}
              </span>
              <span className="order-label value">
                Total Sewa: {currencyIDR(order.amount)}
              </span>
              <span className="order-label value">
                Total Deposit: {currencyIDR(order.orderable.deposit_amount)}
              </span>
            </div>
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">Tanggal</span>
              <span className="order-label value">
                {format(parseISO(order.orderable.start_date), "dd MMM yyyy")} -{" "}
                {format(parseISO(order.orderable.end_date), "dd MMM yyyy")}
              </span>
            </div>
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">Jumlah</span>
              <span className="order-label value">
                {order.orderable.qty} Unit
              </span>
            </div>
            <div className="col-12 col-lg mb-2 text-right">
              <span className="order-label title">Total</span>
              <span className="order-label value">
                {currencyIDR(order.amount + order.orderable.deposit_amount)}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">
                Pengembalian Dana Deposit
              </span>
              <span className="order-label value">
                {order.orderable.order_detail_bank_account.bank}
              </span>
              <span className="order-label value">
                {
                  order.orderable.order_detail_bank_account
                    .holder_account_number
                }
              </span>
              <span className="order-label value">
                {order.orderable.order_detail_bank_account.holder_name}
              </span>
            </div>
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">Pengambilan Modem</span>
              <span className="order-label value">Vending Machine</span>
              <span className="order-label value">Bandara Soekarno Hatta</span>
            </div>
            {order.orderable.return_method === "self-driver" && (
              <div className="col-12 col-lg mb-2">
                <span className="order-label title">Pengembalian Modem</span>
                <span className="order-label value">Kantor Passpod</span>
                <span className="order-label value">
                  Jl. Alaydrus No. 66 B/C, Petojo Utara, Gambir, Jakarta Pusat
                  10130
                </span>
              </div>
            )}
            {order.orderable.return_method === "airport" && (
              <div className="col-12 col-lg mb-2">
                <span className="order-label title">Pengembalian Modem</span>
                <span className="order-label value">
                  {order.orderable.return_order_detail_airport.name}
                </span>
                <span className="order-label value">
                  {format(
                    parseISO(order.orderable.return_order_detail_airport.date),
                    "dd MMM yyyy"
                  )}
                </span>
                <span className="order-label value">
                  {
                    order.orderable.return_order_detail_airport
                      .estimate_time_from
                  }{" "}
                  -{" "}
                  {order.orderable.return_order_detail_airport.estimate_time_to}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ShopOrder({ order }) {
  return (
    <div className="order-list">
      <div className="row">
        <div className="col-auto">
          <img src={SimOrder} alt="Sim Card Order" />
        </div>
        <div className="col">
          <div className="row mb-2">
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">
                {order.orderable.inventory_item.inventory.category.name}
              </span>
              <span className="order-label product-title">
                {order.orderable.inventory_item.inventory.name}
              </span>
              <span className="order-label value">
                {order.orderable.inventory_item.varian_colour.name}
              </span>
              <span className="order-label value">
                {order.orderable.inventory_item.varian_size.name}
              </span>
            </div>
            <div className="col-12 col-lg mb-2">
              <span className="order-label title">Jumlah</span>
              <span className="order-label value">
                {order.orderable.qty} Unit
              </span>
            </div>
            <div className="col-12 col-lg mb-2 text-right">
              <span className="order-label title">Total</span>
              <span className="order-label value">
                {currencyIDR(order.amount)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TravelerInformation({
  salutation,
  first_name,
  last_name,
  email,
  phone_number,
}) {
  return (
    <div className="order-list">
      <div className="row">
        <div className="col-auto">
          <img src={PersonOrder} alt="Pemesan" />
        </div>
        <div className="col">
          <div className="mb-2">
            <span className="order-label title">Title</span>
            <span className="order-label value">{salutation}</span>
          </div>
          <div className="mb-2">
            <span className="order-label title">Nama</span>
            <span className="order-label value">
              {first_name} {last_name}
            </span>
          </div>
          <div className="mb-2">
            <span className="order-label title">Email</span>
            <span className="order-label value">{email}</span>
          </div>
          <div className="mb-2">
            <span className="order-label title">Nomor Telepon</span>
            <span className="order-label value">{phone_number}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
