import { currencyIDR } from "../../utils/helper/numerictools";

export default function WifiTotalOrderBox({
  qty,
  days,
  price,
  rental_fee,
  deposit,
}) {
  return (
    <div className="card-shadow-box order-amount-box">
      <h6 className="title">Pemesanan Anda</h6>
      <div className="amount-detail-section">
        <div className="amount-detail">
          <span className="label-amount">Sewa Modem</span>
          <span className="amount">{currencyIDR(rental_fee)}</span>
        </div>
        <div className="amount-detail">
          <div className="label-amount">
            ({currencyIDR(price)} x {qty} unit x {days} hari)
          </div>
        </div>
        <div className="amount-detail">
          <span className="label-amount">Deposit</span>
          <span className="amount">{currencyIDR(deposit * qty)}</span>
        </div>
        <div className="amount-detail">
          <div className="label-amount">
            ({currencyIDR(deposit)} x {qty} unit)
          </div>
        </div>
      </div>
      <div className="amount-detail-section total-amount">
        <div className="amount-detail">
          <span className="label-amount">Total</span>
          <span className="amount">
            {currencyIDR(rental_fee + deposit * qty)}
          </span>
        </div>
      </div>
    </div>
  );
}
