import { Link } from "react-router-dom";
import { usePresetOrderHooks } from "../../hooks/PresetsHooks";
import { currencyIDR } from "../../utils/helper/numerictools";
import CopyButton from "../CopyButton";
export default function PaymentBCA({ final_amount }) {
  const { presets } = usePresetOrderHooks();

  return (
    <div className="payment-bank-info">
      <div className="row">
        <div className="col-12 col-md-auto">
          <div className="bank-thumb">
            {presets.passpod_banks?.[0]?.image && (
              <img
                src={presets.passpod_banks?.[0]?.image}
                alt="Bank BCA"
                className="bank-thumb"
              />
            )}
          </div>
        </div>
        <div className="col">
          <div className="bank-form-data">
            <span className="label">Nama Rekening</span>
            <span className="value">
              {presets.passpod_banks?.[0]?.holder_name}
            </span>
          </div>
          <div className="bank-form-data">
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <span className="label">Nomor Rekening</span>
                <span className="value value-bank">
                  {presets.passpod_banks?.[0]?.holder_account_number}
                </span>
              </div>
              <CopyButton
                value={presets.passpod_banks?.[0]?.holder_account_number}
              />
            </div>
          </div>
          <div className="bank-form-data">
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <span className="label">Jumlah yang Harus Dibayar</span>
                <span className="value">{currencyIDR(final_amount)}</span>
              </div>
              <CopyButton value={final_amount} />
            </div>
          </div>
        </div>
        <div
          className="col-12 d-flex justify-content-center"
          style={{ paddingTop: "20px" }}
        >
          <Link to="/order" className="btn btn-primary">
            Lihat Status Pembayaran
          </Link>
        </div>
      </div>
    </div>
  );
}
