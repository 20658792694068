export default function WifiBoxPlacholder() {
  return (
    <div className="product-box wifi-placeholder">
      <div className="card-img">
        {/* <img src={modem.image_url} alt={modem.name} /> */}
      </div>
      <div className="card-body">
        <span className="title"></span>
        <div className="price">
          <span className="normal_price"></span>
          <span className="price"></span>
        </div>
      </div>
    </div>
  );
}
