import { apiBasic } from "./base";

export function getProductList(params) {
  let api = apiBasic();
  return api.get("products", { params });
}

export function getProductById(short_id, params) {
  let api = apiBasic();
  return api.get(`products/${short_id}/short`, { params });
}
