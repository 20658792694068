import { parse, format } from "date-fns";

export default function PaymentWithLink({
  img,
  payment_name,
  amount,
  expired_at,
  link,
}) {
  return (
    <div className="payment-with-link text-center">
      <img src={img} alt={payment_name} className="payment-thumb" />
      <div className="payment-link-title">{payment_name}</div>
      <div className="payment-link-amount">
        <div className="label">Pembayaran berlaku hingga</div>
        <div className="amount">
          {format(
            parse(expired_at, "dd-MM-yyyy HH:mm", new Date()),
            "dd MMMM yyyy - HH:mm"
          )}
        </div>
      </div>
      <div className="payment-link-amount">
        <div className="label">Jumlah yang Harus Dibayar</div>
        <div className="amount">{amount}</div>
      </div>

      <a href={link} className="btn btn-primary">
        Lanjutkan Pembayaran
      </a>
    </div>
  );
}
