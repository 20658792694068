export function normalizePhone(number) {
  let phone = String(number).trim();
  if (phone.startsWith("+62")) {
    phone = phone.slice(3);
  } else if (phone.startsWith("62")) {
    phone = phone.slice(2);
  } else if (phone.startsWith("0")) {
    phone = phone.slice(1);
  }
  return phone;
}

export function phoneNumberTest(phone) {
  if (!phone || !/^8[1-9][0-9]{7,10}$/.test(phone)) {
    return false;
  }
  return true;
}

export const isValidEmail = (email) => {
  // Use a regular expression for basic email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
