import { Link } from "react-router-dom";
import { parse, format } from "date-fns";

import { currencyIDR } from "../../utils/helper/numerictools";

export default function PaymentQris({
  barcode_image,
  final_amount,
  expired_at,
}) {
  return (
    <div className="payment-qris text-center">
      <img
        src={barcode_image}
        alt="QRIS barcode"
        className="qris-payment-img mb-2"
      />
      <div className="payment-link-title">QRIS</div>
      <div className="payment-link-amount">
        <div className="label">Kode Berlaku Hingga</div>
        <div className="amount">
          {format(
            parse(expired_at, "dd-MM-yyyy HH:mm", new Date()),
            "dd MMMM yyyy - HH:mm"
          )}
        </div>
      </div>
      <div className="payment-link-amount">
        <div className="label">Jumlah yang Harus Dibayar</div>
        <div className="amount">{currencyIDR(final_amount || 0)}</div>
      </div>
      <Link to="/order" className="btn btn-primary">
        Lihat Status Pembayaran
      </Link>
    </div>
  );
}
