export function ShopDetailLoading() {
  return (
    <div className="shop-detail-loading card-shadow-box">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="img-placeholder"></div>
        </div>
        <div className="col-12 col-md-6 col-lg">
          <div className="placeholder-title"></div>
          <div className="placeholder-price"></div>
        </div>
      </div>
    </div>
  );
}
