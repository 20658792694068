import { useState, useEffect } from "react";
import { getPresetOrder } from "../api/presets";

export function usePresetOrderHooks() {
  const [presets, setPresets] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getPreset = async () => {
    setLoading(true);
    try {
      const { data } = await getPresetOrder();
      setPresets(data.data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPreset();
  }, []);

  return {
    presets,
    errorMsg,
    loading,
    getPreset,
  };
}
