import { currencyIDR } from "../../utils/helper/numerictools";

export default function ShopBox({ shop }) {
  return (
    <div className="product-box shop-box">
      <div className="card-img">
        {shop.inventory_images[0]?.image_url && (
          <img src={shop.inventory_images[0]?.image_url} alt="Gambar Produk" />
        )}
      </div>
      <div className="card-body">
        <span className="title">{shop.name}</span>
        <div className="price">
          <span className="normal_price">
            {shop.normal_price > shop.final_price
              ? currencyIDR(shop.normal_price)
              : null}
          </span>
          <span className="price">{currencyIDR(shop.final_price)}</span>
        </div>
      </div>
    </div>
  );
}
