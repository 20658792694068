import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "jquery";
import "popper.js";
import App from "./App";
// css react datepicker
import "react-datepicker/dist/react-datepicker.css";
// style
import "./theme/styles.css";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
