import CheckoutPage from "./pages/CheckoutPage";
import CheckoutPaymentPage from "./pages/CheckoutPaymentPage";
import HomePage from "./pages/HomePage";
import OrderPage from "./pages/OrderPage";
import ShopAllPage from "./pages/ShopAllPage";
import SimShoPage from "./pages/SimShopPage";
import WifiAllPage from "./pages/WifiAllPage";
import WifiRentPage from "./pages/WifiRentPage";
import PaymentFailedPage from "./pages/payment/FailedPage";
import PaymentPendingPage from "./pages/payment/PendingPage";
import PaymentSuccessPage from "./pages/payment/SuccessPage";

export const router = [
  {
    path: "/wifi-all",
    name: "Semua Wifi",
    component: WifiAllPage,
  },
  {
    path: "/shop-all",
    name: "Semua Travel SIM Card",
    component: ShopAllPage,
  },
  {
    path: "/wifi/:id",
    name: "Rental Wifi",
    component: WifiRentPage,
  },
  {
    path: "/shop/:id",
    name: "Beli SIM Card",
    component: SimShoPage,
  },
  {
    path: "/checkout-payment",
    name: "Checkout Payment",
    component: CheckoutPaymentPage,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutPage,
  },
  {
    path: "/order",
    name: "Order",
    component: OrderPage,
  },
  {
    path: "/payment/success",
    name: "Payment Success",
    component: PaymentSuccessPage,
  },
  {
    path: "/payment/failed",
    name: "Payment Failed",
    component: PaymentFailedPage,
  },
  {
    path: "/payment/pending",
    name: "Payment Pending",
    component: PaymentPendingPage,
  },
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
];

export const menu = [];
