import { Link } from "react-router-dom";
import ShopBox from "./ShopBox";
import { useShopListHooks } from "../../hooks/ShopHooks";
import WifiBoxPlacholder from "../wifi/WifiBoxPlacehoder";

let shop_filter = {
  filter: { limit: 8, currency_code: "IDR", category: "travel-smart-card" },
};

export default function ShopList() {
  let { shopProducts, loading } = useShopListHooks(shop_filter);
  return (
    <section className="product-list shop-list">
      <div className="title-head">
        <h4>Beli SIM Card</h4>
        <Link to="/shop-all" className="btn btn-primary">
          Lihat Semua
        </Link>
      </div>
      <div className="row">
        {loading
          ? [...Array(4)].map((dt, index) => (
              <div className="col-12 col-lg-3" key={index}>
                <WifiBoxPlacholder />
              </div>
            ))
          : null}

        {shopProducts.map((shop) => (
          <div className="col-6 col-lg-3" key={shop.id}>
            <Link to={`/shop/${shop.id}`} className="product-link">
              <ShopBox shop={shop} />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
