import { useState, useEffect } from "react";
import { getAllBanners } from "../api/presets";

export function useBannersHooks({ filter }) {
  const [banners, setBanners] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const getBanners = async (filter = {}) => {
    setLoading(true);
    setFetched(false);
    try {
      const { data } = await getAllBanners({ ...filter });
      setBanners(data.data);
      setFetched(true);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        setErrorMsg(data.message);
      } else {
        setErrorMsg("Something Error Happened");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getBanners(filter);
  }, [filter]);

  return {
    banners,
    fetched,
    setBanners,
    errorMsg,
    loading,
    getBanners,
  };
}
