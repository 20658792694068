import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import NotFoundPage from "./pages/NotFound";
import { router } from "./router";
import { OrderQueryProvider } from "./utils/context/OrderQueryContext";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <OrderQueryProvider>
      <Router>
        <ScrollToTop />
        <Switch>
          {router.map((route, index) => (
            <Route
              path={route.path}
              exact={route.path === "/"}
              component={route.component}
              key={index}
            />
          ))}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </OrderQueryProvider>
  );
}

export default App;
