import { apiBasic } from "./base";

export function createGuestOrder(data) {
  let api = apiBasic();
  return api.post("orders/guest", data);
}

export function getGuestOrder(id) {
  let api = apiBasic();
  return api.get(`orders/guest/${id}`);
}

export function paymentBankGuestOrder(id) {
  let api = apiBasic();
  return api.put(`orders/guest/${id}/banks`);
}

export function paymentQrisGuestOrder(id) {
  let api = apiBasic();
  return api.post(`orders/guest/${id}/qris`);
}

export function paymentGopayGuestOrder(id) {
  let api = apiBasic();
  return api.post(`orders/guest/${id}/gopay`);
}
