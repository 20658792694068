import PaymentSuccessIcon from "../assets/img/payment-success-icon.svg";
import PaymentPendingIcon from "../assets/img/payment-pending-icon.svg";
import PaymentFailedIcon from "../assets/img/payment-failed-icon.svg";

import MainLayout from "../components/layouts/MainLayout";
import {
  ModemOrder,
  ShopOrder,
  TravelerInformation,
} from "../components/order/CheckoutOrder";
import CheckoutPlaceholder from "../components/order/CheckoutPlaceholder";
import { useGuestOrderHooks } from "../hooks/OrderHooks";
import { currencyIDR } from "../utils/helper/numerictools";
import OrderNotReady from "../components/order/OrderNotReady";

export default function OrderPage() {
  const { orderData, orderId, loading, fetched } = useGuestOrderHooks();

  return (
    <MainLayout>
      <main>
        <div className="container">
          <h3 className="text-center mb-4">Pemesanan</h3>
          <div className="row">
            {/* Order Box */}
            <div className="col-12 col-lg-8 mx-auto">
              {orderId === null && <OrderNotReady />}
              {loading && <CheckoutPlaceholder />}
              {fetched && (
                <div className="card-shadow-box">
                  <div className="order-payment-title">
                    <h4>Pemesanan</h4>
                    <span className="order-number">
                      {orderData.order_number}
                    </span>
                  </div>
                  <hr className="order-divider" />
                  <h6>Status Pembayaran</h6>
                  <div className="order-status-info">
                    <div className="row">
                      <div className="col-auto">
                        {orderData.status === "confirmed" && (
                          <img
                            src={PaymentSuccessIcon}
                            alt="Order Status Icon"
                          />
                        )}
                        {orderData.status === "payment-pending" && (
                          <img
                            src={PaymentPendingIcon}
                            alt="Order Status Icon"
                          />
                        )}
                        {(orderData.status === "expired" ||
                          orderData.status === "canceled" ||
                          orderData.status === "payment-canceled") && (
                          <img
                            src={PaymentFailedIcon}
                            alt="Order Status Icon"
                          />
                        )}
                      </div>
                      <div className="col">
                        <div className="order-status">
                          <span className="label">Status Pembayaran</span>
                          {orderData.status === "confirmed" && (
                            <span className="value">Sudah Lunas</span>
                          )}
                          {orderData.status === "payment-pending" && (
                            <span className="value">Menunggu Pembayaran</span>
                          )}
                          {orderData.status === "expired" && (
                            <span className="value">Pesanan kedaluwarsa</span>
                          )}
                          {orderData.status === "canceled" && (
                            <span className="value">Pesanan Dibatalkan</span>
                          )}
                          {orderData.status === "payment-failed" && (
                            <span className="value">Pembayaran Gagal</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {orderData.order_pickup && (
                    <div className="vending-machine-pickup">
                      <span className="label-title">
                        Kode Pengambilan Vending Machine
                      </span>
                      <span className="label-pickup-number">
                        {orderData.order_pickup.code}
                      </span>
                    </div>
                  )}
                  {orderData.status === "confirmed" && (
                    <div className="alert alert-info">
                      <h6>Informasi</h6>
                      <ul className="pl-3">
                        <li>
                          Untuk pengambilan Modem/SIM Card melalui Vending
                          Machine silahkan masukkan Redeem Code yang diberikan
                          yang dikirimkan juga melalui email.
                        </li>
                        <li>
                          Jika sudah menerima Modem, harap tunjukkan nomor Modem
                          ke petugas bandara Passpod atau kirim foto SSID Modem
                          yang ada di bagian belakang modem via Whatsapp ke CS
                          Passpod di nomor 08881171819 (24 JAM).
                        </li>
                      </ul>
                    </div>
                  )}
                  <hr className="order-divider" />
                  <h6>Rincian Pemesanan</h6>
                  {orderData.order_details?.map((order) => (
                    <div key={order.id}>
                      {order.type === "inventory" && (
                        <ShopOrder order={order} />
                      )}
                      {order.type === "modem" && <ModemOrder order={order} />}
                    </div>
                  ))}
                  <div className="order-list text-right">
                    <span className="order-label title">
                      Biaya Administrasi
                    </span>
                    <span className="order-label value">
                      {currencyIDR(orderData?.total_admin_fee)}
                    </span>
                    <span className="order-label title">Total Akhir</span>
                    <span className="order-label value">
                      {currencyIDR(orderData?.final_amount)}
                    </span>
                  </div>

                  <h6>Detail Wisatawan</h6>
                  <TravelerInformation
                    salutation={orderData.salutation}
                    first_name={orderData.first_name}
                    last_name={orderData.last_name}
                    phone_number={orderData.phone_number}
                    email={orderData.email}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
}
